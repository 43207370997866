import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Tooltip,
  useMediaQuery,
  FormHelperText
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { toast } from "react-toastify";
import axiosInstance from "../service/axiosInstance";

function Profile() {
  const isMobile = useMediaQuery("(max-width:1100px)");
  const navigate = useNavigate();
  const [getState, setGetState] = useState({});
  const handleLogout = () => {
    localStorage.clear("experianData");
    localStorage.clear("TDFToken");
    localStorage.clear("loginConform");
    navigate("/");
  };
  const handlegoProfile = () => {
    navigate("/dashboard/profile");
  };
  const [formData, setFormData] = useState({
    name: "",
    surName: "",
    mobile: "",
    gender: "",
    email: "",
    dob: "",
    pan: "",
    address: "",
    city: "",
    pinCode: "",
    state: "",
    employmentType: "",
    monthlyIncome: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    surName: "",
    mobile: "",
    gender: "",
    email: "",
    dob: "",
    pan: "",
    address: "",
    city: "",
    pinCode: "",
    state: "",
    employmentType: "",
    monthlyIncome: "",
  });
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "pan" ? value.toUpperCase() : value,
    });
  };

  useEffect(() => {
    const getLocalStorage = localStorage.getItem("TDFToken");
    const token = getLocalStorage;
    axiosInstance
      .get("/tdf/api/user/getProfileDetail", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFormData({
          ...formData,
          name: res?.data?.name,
          surName: res?.data?.surName,
          mobile: res?.data?.mobile,
          gender: res?.data?.gender,
          email: res?.data?.email,
          dob: res?.data?.dob,
          pan: res?.data?.pan,
          address: res?.data?.address,
          city: res?.data?.city,
          pinCode: res?.data?.pinCode,
          state: res?.data?.state,
          employmentType: res?.data?.employmentType,
          monthlyIncome: res?.data?.monthlyIncome,
        });
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);
  useEffect(() => {
    const getLocalStorage = localStorage.getItem("TDFToken");
    const token = getLocalStorage;
    axiosInstance
      .get("/tdf/api/getState", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setGetState(res?.data);
      })
      .catch((err) => {
        console.log("err:", err);
        toast.error(err?.message);
      });
  }, []);
  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!formData.name) {
      errors.name = "Name is Required";
      isValid = false;
    }
    if (!formData.surName) {
      errors.surName = "Last Name is Required";
      isValid = false;
    }
    if (!formData.mobile) {
      errors.mobile = "mobileNo is Required";
      isValid = false;
    }
    if (!formData.gender) {
      errors.gender = "gender is Required";
      isValid = false;
    }
    if (!formData.email) {
      errors.email = "email is Required";
      isValid = false;
    }
    if (!formData.dob) {
      errors.dob = "dob is Required";
      isValid = false;
    }
    if (!formData.pan) {
      errors.pan = "Pan Number is Required";
      isValid = false;
    }
    if (!formData.address) {
      errors.address = "address is Required";
      isValid = false;
    }
    if (!formData.city) {
      errors.city = "city is Required";
      isValid = false;
    }
    if (!formData.address) {
      errors.address = "address is Required";
      isValid = false;
    }
    if (!formData.pinCode) {
      errors.pinCode = "pincode is Required";
      isValid = false;
    }
    if (!formData.state) {
      errors.state = "state is Required";
      isValid = false;
    }
    if (!formData.employmentType) {
      errors.employmentType = "Employment Type is Required";
      isValid = false;
    }
    if (!formData.monthlyIncome) {
      errors.monthlyIncome = "Monthly Income is Required";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const getLocalStorage = localStorage.getItem("TDFToken");
    const token = getLocalStorage;
    if (validate()) {
      axiosInstance
        .post(
          "/tdf/api/user/v2/submitProfile",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success(res?.data?.message);
          navigate("/dashboard/reports")
        })
        .catch((err) => {
          console.log("err:", err);
          toast.error(err?.message);
        });
    }
  };
  return (
    <>
      <Box style={{ padding: "32px" }}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography style={{ color: "#044a67", fontSize: "20px" }}>
              Hi ! {formData?.name}{" "}{formData?.surName}
            </Typography>
            <Typography style={{ color: "#044a67", fontSize: "20px" }}>
              It's good to see you again.
            </Typography>
          </Box>
          {!isMobile && (
            <Box style={{ display: "flex" }}>

              <SettingsIcon
                style={{ marginRight: "20px", cursor: "pointer" }}
                onClick={handlegoProfile}
              />
              <Tooltip title="Logout">
                <LogoutIcon onClick={handleLogout} style={{ cursor: "pointer" }} />
              </Tooltip>
            </Box>
          )}

        </Box>

        <Box
          sx={{
            border: "2px solid whitesmoke",
            width: { sm: "100%", lg: "100%" },
            background: "#FFFFFF",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "20px",
            marginTop: "40px",
          }}
        >
          <Box style={{ padding: "40px" }}>
            <Typography
              variant="h5"
              style={{
                marginBottom: "20px",
                fontSize: "24px",
                fontWeight: "400",
              }}
            >
              User Details
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container
                spacing={{ xs: 3, sm: 3, md: 4, lg: 4 }}
              >
                <Grid item xs={12} sm={6} lg={6}>
                  <label>First Name</label>
                  <TextField
                    type="text"
                    style={{ width: "100%", marginTop: "5px" }}
                    placeholder="Enter First Name"
                    value={formData.name}
                    name="name"
                    onChange={handleChange}
                    size="small"
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <label>Last Name</label>
                  <TextField
                    type="text"
                    style={{ width: "100%", marginTop: "5px" }}
                    placeholder="Enter Last Name"
                    value={formData.surName}
                    name="surName"
                    onChange={handleChange}
                    size="small"
                    error={!!errors.surName}
                    helperText={errors.surName}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <label>Phone Number</label>
                  <TextField
                    type="number"
                    style={{ width: "100%", marginTop: "5px" }}
                    placeholder="Enter Phone Number"
                    value={formData.mobile}
                    name="mobile"
                    onChange={handleChange}
                    size="small"
                    id="phone-input"
                    error={!!errors.mobile}
                    helperText={errors.mobile}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <label>Gender</label>
                  <FormControl fullWidth>
                    <Select
                      value={formData.gender}
                      onChange={handleChange}
                      placeholder="Gender"
                      size="small"
                      style={{ marginTop: "5px" }}
                      name="gender"
                      displayEmpty
                      error={!!errors.gender}
                      // helperText={errors.gender}
                    >
                      <MenuItem value="" disabled>
                        Select Gender
                      </MenuItem>
                      <MenuItem value={1}>Male</MenuItem>
                      <MenuItem value={2}>Female</MenuItem>
                    </Select>
                    {errors.gender && <FormHelperText>{errors.gender}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <label>Email</label>
                  <TextField
                    type="email"
                    style={{ width: "100%", marginTop: "5px" }}
                    placeholder="Enter Email"
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                    size="small"
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <label>DOB</label>
                  <TextField
                    type="date"
                    style={{ width: "100%", marginTop: "5px" }}
                    placeholder="Enter DOB"
                    value={formData.dob}
                    name="dob"
                    onChange={handleChange}
                    size="small"
                    error={!!errors.dob}
                    // helperText={errors.dob}
                  />
                    {errors.dob && <FormHelperText>{errors.dob}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <label>Pan Number</label>
                  <TextField
                    type="text"
                    style={{ width: "100%", marginTop: "5px" }}
                    placeholder="Pan Number"
                    value={formData.pan}
                    name="pan"
                    onChange={handleChange}
                    size="small"
                    error={!!errors.pan}
                    helperText={errors.pan}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <label>Address</label>
                  <TextField
                    type="text"
                    style={{ width: "100%", marginTop: "5px" }}
                    placeholder="Address"
                    value={formData.address}
                    name="address"
                    onChange={handleChange}
                    size="small"
                    error={!!errors.address}
                    helperText={errors.address}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <label>City</label>
                  <TextField
                    type="text"
                    style={{ width: "100%", marginTop: "5px" }}
                    placeholder="Enter City"
                    value={formData.city}
                    name="city"
                    onChange={handleChange}
                    size="small"
                    error={!!errors.city}
                    helperText={errors.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <Box style={{ marginTop: "5px" }}>
                    <label>State</label>
                    <FormControl fullWidth>
                      <Select
                        value={formData.state}
                        onChange={handleChange}
                        name="state"
                        size="small"
                        error={!!errors.state}
                        // helperText={errors.state}
                      >
                        {Object.entries(getState).map(([code, name]) => (
                          <MenuItem key={code} value={code}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    {errors.state && <FormHelperText>{errors.state}</FormHelperText>}

                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <label>Pincode</label>
                  <TextField
                    type="text"
                    style={{ width: "100%", marginTop: "5px" }}
                    placeholder="Enter Pincode"
                    value={formData.pinCode}
                    name="pinCode"
                    onChange={handleChange}
                    size="small"
                    error={!!errors.pinCode}
                    helperText={errors.pinCode}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <label>Employment Type</label>
                  <FormControl fullWidth>
                    <Select
                      value={formData.employmentType}
                      onChange={handleChange}
                      placeholder="Employment Type"
                      size="small"
                      style={{ marginTop: "5px" }}
                      name="employmentType"
                      displayEmpty
                      error={!!errors.employmentType}
                      // helperText={errors.employmentType}
                    >
                      <MenuItem value="" disabled>
                        Employment Type
                      </MenuItem>
                      <MenuItem value={"SALARIED"}>SALARIED</MenuItem>
                      <MenuItem value={"SELF_EMPLOYED"}>SELF_EMPLOYED</MenuItem>
                    </Select>
                    {errors.employmentType && <FormHelperText>{errors.employmentType}</FormHelperText>}

                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <label>Monthly Income</label>
                  <TextField
                    type="number"
                    style={{ width: "100%", marginTop: "5px" }}
                    placeholder="monthly Income"
                    value={formData.monthlyIncome}
                    name="monthlyIncome"
                    onChange={handleChange}
                    size="small"
                    error={!!errors.monthlyIncome}
                    helperText={errors.monthlyIncome}
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                type="submit"
                style={{
                  textTransform: "capitalize",
                  background: "#044A67",
                  color: "#fff",
                  marginTop: "20px",
                  width: "150px",
                }}
              >
                Submit
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Profile;
