import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function HomeFaq() {
  const [showAll, setShowAll] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleSeeMoreClick = () => {
    setShowAll(!showAll);
  };
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false); // Only the clicked panel will stay expanded
  };

  return (
    <>
      <Box sx={{ marginTop: "40px" }}>
        <Typography
          sx={{
            fontSize: {
              xs: "35px",
              sm: "35px",
              md: "45px",
              lg: "58px",
            },
            padding: "10px",
            fontFamily: "CustomFont",
            textAlign: "center",
          }}
        >
          Got questions? We have the answers
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop: "60px",
          paddingRight: {
            xs: "30px",
            sm: "30px",
            lg: "200px",
            md: "200px",
          },
          paddingLeft: {
            xs: "30px",
            sm: "30px",
            lg: "200px",
            md: "200px",
          },

        }}
      >
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleAccordionChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            style={{ fontSize: "15px", fontWeight: "500", fontFamily: 'CustomFontMedium', padding: "5px 20px", }}
          >
            What is a credit score?
          </AccordionSummary>
          <AccordionDetails style={{ fontSize: "13px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}>
            A credit score is a 3-digit numeric score that determines your
            credit worthiness. Your credit score typically falls between
            350-900, and the higher your score the stronger is your credit
            worthiness.
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginTop: "10px" }} expanded={expanded === "panel2"}
          onChange={handleAccordionChange("panel2")}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            style={{ padding: "5px 20px", fontSize: "15px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
          >
            How is a credit score calculated?
          </AccordionSummary>
          <AccordionDetails style={{ fontSize: "13px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}>
            <Typography style={{ fontSize: "13px" }}>
              Your credit score is derived from your past history on:
            </Typography>
            <ul style={{ paddingLeft: "20px" }}>
              <li>Amount of debt</li>
              <li>Repayment of loans, credit cards</li>
              <li>Number of open credit lines</li>
              <li>Type and sub-type of loans</li>
              <li>Tenure of loans</li>
              <li>Recency of inquiry for new credit line</li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }} expanded={expanded === "panel3"}
          onChange={handleAccordionChange("panel3")}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
            style={{ fontSize: "15px", }}
          >
            Who calculates my Credit Score?
          </AccordionSummary>
          <AccordionDetails style={{ fontSize: "13px" }}>
            In India, there are 4 credit information companies or credit bureaus
            licensed by the Reserve Bank of India (RBI), who have the authority
            to access your credit information and define your credit score. They
            are:
            <ol style={{ paddingLeft: "20px" }}>
              <li>
                {" "}
                <b>CIBIL</b> - The CIBIL score is almost synonymous with ‘credit
                score’ in India. Their credit score range is between 300 and
                900.
              </li>
              <li>
                {" "}
                <b>Equifax</b> – A joint venture with Equifax Inc. USA and
                leading financial institutions in India., Equifax credit score
                range is between 300 and 850.
              </li>
              <li>
                <b>Experian</b> – The latest credit bureau to be licensed by the
                RBI in 2010, their credit scores range between 300 and 850.
              </li>
              <li>
                <b>CRIF Highmark</b> – their credit scores range between 300 to
                900.
              </li>
            </ol>
          </AccordionDetails>
        </Accordion>

        {/* Conditional rendering of additional Accordion components */}
        {showAll && (
          <>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel4"}
              onChange={handleAccordionChange("panel4")} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                How do I check my credit score?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                On Dues Factory , you can get your credit score here absolutely
                free. <br />
                <br />
                Alternatively, You can go to the websites of any of the 4
                licensed credit bureaus in India, fill in your details and
                request for a credit score upon payment of a nominal fee.
              </AccordionDetails>
            </Accordion>

            {/* Add more Accordion components here as needed */}
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }} expanded={expanded === "panel5"}
              onChange={handleAccordionChange("panel5")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5-content"
                id="panel5-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                Why is my credit score important?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                While evaluating your loan application, Banks and
                financial/lending institutions need to gauge your credit
                worthiness. To do so, they use the services of these authorized
                credit bureaus to obtain an extensive credit report of you or
                your business’s credit history to underwrite your loan
                application. A good credit score makes it easier for you to
                secure loans or extended credit lines.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }} expanded={expanded === "panel6"}
              onChange={handleAccordionChange("panel6")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                What is a good credit score?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                A credit score of 750 or above is considered a good credit
                score. It helps you qualify for loans or credit cards better
                because it gives your potential lenders more confidence in
                approving your requests for loans and other credit.
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel7"}
              onChange={handleAccordionChange("panel7")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                How is a high credit score useful?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                A credit score of 750 or above allows you a faster loan approval
                process. It also helps you avail attractive credit terms like
                lower interest rates, better terms of repayment etc.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel8"}
              onChange={handleAccordionChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                What are the disadvantages of having a bad credit score?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                A credit score of 650 or lower is considered a low credit score
                and a credit score of 400 and lower is considered a bad credit
                score. With such scores you become too high a risk for the
                bigger banks and lending institutions
                <ul style={{ paddingLeft: "20px" }}>
                  <li>You get loans at very high interest rates.</li>
                  <li>
                    You delay building your wealth, because a lot of it goes
                    towards repaying high interest
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel9"}
              onChange={handleAccordionChange("panel9")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                Can I improve my credit score?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                Yes you can improve your credit score if you take conscious and
                consistent measures to improve it. However, credit scores do not
                get impacted immediately after you have prepaid a loan or paid
                up any outstanding dues. It takes at least 45-60 days for your
                credit score to climb.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel10"}
              onChange={handleAccordionChange("panel10")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                Does prepaying a loan impact my credit score?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                Pre-paying a loan is different from paying the full outstanding
                amount on your credit cards. If you prepay your loan, your
                credit report will show it as a closed account. Which means it
                will not be considered when calculating your credit score, as it
                takes only open credit lines in consideration. So while
                prepaying your loan will not decrease your credit score, it may
                not necessarily increase it either.
                <br />
                <br />
                So if you want to build a good credit history, then it is better
                to keep the loan active, which shows that you have the
                capability of consistently paying your EMIs. This helps improve
                your credit score in the long run.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel11"}
              onChange={handleAccordionChange("panel11")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                Do delayed credit card payments affect my credit score?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                Yes. Delayed payments on your credit card can cause your credit
                score to drop. However, a single late payment may not affect
                your score that much, if you make the payment within 30 days of
                the due date. However, if you delay making payments towards your
                credit card frequently, then your credit score may get impacted
                by more than 100 points. either.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel12"}
              onChange={handleAccordionChange("panel12")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                What is a soft inquiry against my loan application?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                A soft inquiry is usually when you check your own credit score,
                irrespective of whether you have applied for a loan/credit card
                or not. Soft inquiries, therefore, do not affect your credit
                score.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel13"}
              onChange={handleAccordionChange("panel13")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                There have been hard inquiries against my loan? What does that
                mean?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                Lender-initiated inquiries received by credit bureaus towards
                assessing your credit worthiness are called Hard Inquiries. If
                your profile receives a hard inquiry for your application for
                loan, mortgage or credit card, it gets recorded on your credit
                report and negatively impacts your credit score. Hard inquiries
                tend to stay on your credit report for about 2 years.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel14"}
              onChange={handleAccordionChange("panel14")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                Why should I worry about keeping a good credit score if I don’t
                require a loan?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                You may not require a loan or a credit card today, but you may
                need one in the future. If you have a credit card, making timely
                payments and not defaulting on any payments will keep your
                credit score high. So when it’s time to apply for that loan,
                your application will get processed faster and you will be able
                to enjoy better interest and other terms.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel15"}
              onChange={handleAccordionChange("panel15")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                Why should I worry about keeping a good credit score if I don’t
                require a loan?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                You may not require a loan or a credit card today, but you may
                need one in the future. If you have a credit card, making timely
                payments and not defaulting on any payments will keep your
                credit score high. So when it’s time to apply for that loan,
                your application will get processed faster and you will be able
                to enjoy better interest and other terms.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel16"}
              onChange={handleAccordionChange("panel16")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                What kind of transactions are considered by the credit bureaus
                to calculate my credit score?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                You may not require a loan or a credit card today, but you may
                need one in the future. If you have a credit card, making timely
                payments and not defaulting on any payments will keep your
                credit score high. So when it’s time to apply for that loan,
                your application will get processed faster and you will be able
                to enjoy better interest and other terms.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel17"}
              onChange={handleAccordionChange("panel17")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                How soon can I expect my credit score to improve?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                Resolution of an open credit line takes about 30 days to impact
                your credit score. <br /> <br />
                OR
                <br /> <br />
                We estimate an average 20 days for your credit score to improve
                after you have resolved one or more open credit lines.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel18"}
              onChange={handleAccordionChange("panel18")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                Are there any charges associated with Due factory Services
                provided to the customers?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                We do not charge for your credit score report. We do charge a
                Consulting Fee for offering custom-made suggestions and the best
                possible options for you to resolve your open credit lines.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel19"}
              onChange={handleAccordionChange("panel19")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                I have a dispute with a lender. Can I get help from due factory
                services?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                Due factory is not a dispute resolution platform . However, in
                case you have a dispute with a lender in terms of the amount
                outstanding, we provide help in getting the best possible
                settlement offers from the lenders and in the process improving
                your credit health.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }} expanded={expanded === "panel20"}
              onChange={handleAccordionChange("panel20")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                The loan/ Credit card outstanding reflecting in my bureau report
                is not mine.. What should I do ?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                While it is not common for credit reports to have errors, you
                must immediately raise a dispute with the credit bureau if you
                see a loan in your credit report that is not yours. The bank may
                have made a genuine mistake, or it is possible that you may be a
                guarantor for someone else’s loan on which s/he has defaulted.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }}
              expanded={expanded === "panel21"}
              onChange={handleAccordionChange("panel21")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                Does taking/ getting a new loan impact my credit score?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                Getting a new loan by itself will not affect your credit score.
                However, you may find it difficult taking on additional credit
                before the earlier loan is paid back.
                <br />
                <br />
                In the situation where you have had a loan application get
                rejected in the recent past, we suggest not applying for a fresh
                loan till you have improved your credit score.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }} expanded={expanded === "panel22"}
              onChange={handleAccordionChange("panel22")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                What is FOIR?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                The Fixed Obligations to Income Ratio (FOIR) is also known as
                debt-to-income ratio. It is a metric used by banks and NBFCs to
                evaluate an individual's loan eligibility. Ideally, FOIR of 40%
                - 50% is considered good. This means that your total monthly
                expenses are not more than 40%-50% of your income. The lower
                your FOIR the higher the chance of getting loan approvals.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }} expanded={expanded === "panel23"}
              onChange={handleAccordionChange("panel23")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                Can I get my late payment charges waived off my credit card ?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                Almost all credit card issuers usually waive off a part or the
                complete late payment charge on a one-time basis if you make a
                request to do so. However, the waiver is at their own
                discretion. They are not obligated to do so.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: "10px", fontWeight: "500", fontFamily: 'CustomFontMedium' }} expanded={expanded === "panel24"}
              onChange={handleAccordionChange("panel24")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontSize: "15px", padding: "5px 20px", }}
              >
                How can I get a waiver on my loan ?
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "13px" }}>
                Waiver on loans is only given by the government in extraordinary
                circumstances for e.g. waiver of loans for drought affected
                farmers who were hit by dismal crop production. Lending
                institutions levy heavy fines for delayed EMIs, so we recommend
                taking a loan only if you are confident of your loan-paying
                ability for the entire tenure.
              </AccordionDetails>
            </Accordion>
          </>
        )}

        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
        >
          <Button
            sx={{
              width: "195px",
              height: "45px",
              color: "#030303",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              fontFamily: "",
              fontSize: "22px",
              borderRadius: "40px",
              boxShadow: "0 10px 40px #00000029",
              textTransform: "capitalize",
              fontFamily: 'CustomFontMedium',
            }}
            onClick={handleSeeMoreClick}
          >
            {showAll ? "See Less" : "See More"}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default HomeFaq;
