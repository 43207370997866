import React, { useEffect, useState } from "react";
import banner from "../assets/imgs/TDF-full-img.png";
import { GiCheckMark } from "react-icons/gi";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  Select,
  FormControlLabel,
  Checkbox,
  Switch,
  Modal,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../service/axiosInstance";
import Loading from "../Components/Loading";
import SSLLogo from "../assets/homePage2/SSL.svg";
import HeaderComponent from "../Components/HeaderCoponent";

function ExperianFullForm() {
  const navigate = useNavigate();
  const handleNavigateLogo = () => {
    navigate("/");
  };

  // State for text visibility
  const [showFirstSet, setShowFirstSet] = useState(true);

  const [isChecked, setIsChecked] = useState(true);
  const [checkedWhatsApp, setCheckedWhatsApp] = useState(true);
  const [profileDetail, setProfileDetail] = useState({});
  const [formData, setFormData] = useState({
    firstName: "",
    surName: "",
    mobileNo: "",
    gender: "",
    email: "",
    whatsAppConsent: checkedWhatsApp,
    experianConsent: isChecked,
    dateOfBirth: "",
    pan: "",
    flatno: "",
    city: "",
    pincode: "",
    state: "",

  });
  const [rawDateOfBirth, setRawDateOfBirth] = useState("")

  const [loading, setLoading] = useState(false);
  const [getState, setGetState] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Toggle between first set and second set of texts
  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstSet((prevShowFirstSet) => !prevShowFirstSet);
    }, 4000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Regex for first name and surname validation
    const nameRegex = /^[A-Za-z]{0,15}$/;

    // Regex for PAN card number validation
    const panCardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    if (name === "firstName" || name === "surName") {
      if (nameRegex.test(value)) {
        const formattedValue =
          value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        setFormData({ ...formData, [name]: formattedValue });
      }
    } else if (name === "pan") {
      setFormData({ ...formData, [name]: value.toUpperCase() });
    }
    else if (name === "dateOfBirth") {
      // Store the raw date in 'YYYY-MM-DD' format for input handling
      const rawDate = value;

      // Convert the raw date to 'DD-MMM-YYYY' format
      const date = new Date(rawDate);
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-GB', options).replace(/ /g, '-');

      setFormData({
        ...formData,
        dateOfBirth: formattedDate  // Set the formatted date
      });
      setRawDateOfBirth(rawDate)
    }
    else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  // Toggle between first set and second set of texts
  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstSet((prevShowFirstSet) => !prevShowFirstSet);
    }, 4000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
  // Function to handle the change event of the WhatsApp switch
  const handleChangeCheckedWhats = (event) => {
    setCheckedWhatsApp(event.target.checked); // <-- Update state based on switch toggle
  };
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      whatsAppConsent: checkedWhatsApp,
    }));
  }, [checkedWhatsApp]);
  const handleExperianSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const getLocalStorage = localStorage.getItem("TDFToken");
    const token = getLocalStorage;
    axiosInstance
      .post("/tdf/api/user/fetchReport", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        toast.success(res?.data?.msg);
        navigate("/login/otpverification");
      })
      .catch((err) => {
        setLoading(false);
        console.log("handleExperianSubmit:", err);
        toast.error(err?.message);
      });
  };
  useEffect(() => {
    const getLocalStorage = localStorage.getItem("TDFToken");
    const token = getLocalStorage;
    axiosInstance
      .get("/tdf/api/user/getProfileDetail", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProfileDetail(res.data);
        
        
        setFormData({
          ...formData,
          firstName: res?.data?.name,
          surName: res?.data?.surName,
          mobileNo: res?.data?.mobile,
          gender: res?.data?.gender,
          email: res?.data?.email,

          city: res?.data?.city,
          flatno: res?.data?.address,
          pincode: res?.data?.pinCode,
          pan: res?.data?.pan,
          dateOfBirth: res?.data?.dob,
          // state: res?.data?.state ,
        });
      })
      .catch((err) => {
        toast.error(err?.message);
      });
      
  }, []);

  useEffect(() => {
    const getLocalStorage = localStorage.getItem("TDFToken");
    const token = getLocalStorage;
    axiosInstance
      .get("/tdf/api/getState", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {        
       const data =  Object.values(res?.data).sort()
        setGetState(data);
        
      })
      .catch((err) => {
        console.log("err:", err);
        toast.error(err?.message);
      });
  }, []);
  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) : (

        <>
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column", // Makes sure elements stack vertically
              marginTop: 0, // Remove default margin
              padding: 0, // Remove padding
            }}
          >
            <Box
              sx={{
                height: "1.5rem", // Matches your marginTop
                backgroundColor: "#044A67", // Fills the top space with yellow
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: {
                  xs: "0 16px",
                  sm: "0 16px",
                  md: "0 35px",
                  lg: "2px 35px",
                },
                background: "#044A67",
              }}
            >
              <Box tyle={{ padding: "20px" }}>
                <img
                  src={whiteLogo}
                  alt="logo"
                  style={{
                    width: "145px",
                    height: "auto",
                    cursor: "pointer",
                  }}
                  onClick={handleNavigateLogo}
                />
              </Box>
            </Box>
          </Box> */}

          <Box
            style={{
              background: "#044A67",
              width: "100%",
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
                <HeaderComponent />

            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={12}
                lg={6}
                md={6}
                // style={{ paddingLeft: "20px" }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={banner}
                  alt="pullLogo"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    width: "80%",
                  }}
                />
                <Box
                  sx={{
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    lineHeight: "30px",
                    fontSize: "24px",
                    marginTop: "10px",
                    fontFamily: "CustomFontMedium",
                    margin: { xs: "10px", lg: "none" },
                    textAlign: "center",

                  }}
                >
                  When you share your details, you’ll get:
                </Box>
                <Box
                  sx={{
                    marginTop: "10px",
                    width: { lg: "50%", sm: "50%" },
                    // marginLeft: { lg: "20%", sm: "20%" },

                    padding: { xs: "10px 20px", lg: "10px", },

                  }}
                >
                  {showFirstSet ? (
                    <>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <GiCheckMark
                          style={{
                            color: "#ffff",
                            marginTop: "5px",
                            fontSize: "12px",
                          }}
                        />
                        <Typography
                          style={{
                            color: "#ffff",
                            marginLeft: "10px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          Free Experian Credit Score and Report
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: "10px",
                          fontFamily: "CustomFontMedium",
                        }}
                      >
                        <GiCheckMark
                          style={{ color: "#ffff", marginTop: "5px" }}
                        />
                        <Typography
                          style={{
                            color: "#ffff",
                            marginLeft: "10px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          Monitor your score improvements - Report and Score
                          Refreshed Every 45 Days, on Sign In
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: "10px",
                          fontFamily: "CustomFontMedium",
                        }}
                      >
                        <GiCheckMark
                          style={{ color: "#ffff", marginTop: "5px" }}
                        />
                        <Typography
                          style={{
                            color: "#ffff",
                            marginLeft: "10px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          Complimentary Alerts and Recommendations from Due
                          Factory
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: "10px",
                        }}
                      >
                        <GiCheckMark
                          style={{ color: "#ffff", marginTop: "5px" }}
                        />
                        <Typography
                          style={{
                            color: "#ffff",
                            marginLeft: "10px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          We help you prioritize your repayments based on deep
                          data insights.
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: "10px",
                        }}
                      >
                        <GiCheckMark
                          style={{ color: "#ffff", marginTop: "5px" }}
                        />
                        <Typography
                          style={{
                            color: "#ffff",
                            marginLeft: "10px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          Take decisions which change your future for the
                          better.
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
                <Box
                  style={{
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "none", lg: "50%", sm: "50%" },
                      background: "#fff",
                      padding: "40px",
                      padding: { xs: "16px", lg: "40px", },
                      borderRadius: "5px",
                      boxShadow:
                        "0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "CustomFontMedium",
                      }}
                    >
                      Safe and Secure
                    </Typography>
                    <Box
                      sx={{
                        margin: "15px 0",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img src={SSLLogo} alt="SSL" width={"120px"} />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "CustomFontMedium",
                          fontSize: "14px",
                          color: "#044a67",
                        }}
                      >
                        The information you provide will be transferred to us
                        through a private, secure connection.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={6}
                md={6}
                style={{
                  paddingRight: "20px",
                  paddingLeft: "25px",
                  marginBottom: "20px",
                }}
              >
                <Box
                  sx={{
                    border: "2px solid whitesmoke",
                    width: { sm: "100%", lg: "100%" },
                    background: "#FFFFFF",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      padding: { xs: "20px", lg: "40px" },
                      boxShadow:
                        "0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)",
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        marginBottom: "20px",
                        fontSize: "24px",
                        fontWeight: "400",
                      }}
                    >
                      Verify /Fill personal details
                    </Typography>
                    <form onSubmit={handleExperianSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={6}>
                          <label>First Name</label>
                          <TextField
                            type="text"
                            style={{ width: "100%", marginTop: "5px" }}
                            placeholder="Enter First Name"
                            value={formData.firstName}
                            name="firstName"
                            onChange={handleChange}
                            size="small"
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={6}>
                          <label>Last Name</label>
                          <TextField
                            type="text"
                            style={{ width: "100%", marginTop: "5px" }}
                            placeholder="Enter Last Name"
                            value={formData.surName}
                            name="surName"
                            onChange={handleChange}
                            size="small"
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={6}>
                          <label>Phone Number</label>
                          <TextField
                            type="number"
                            style={{ width: "100%", marginTop: "5px" }}
                            placeholder="Enter Phone Number"
                            value={formData.mobileNo}
                            disabled
                            name="mobileNo"
                            onChange={handleChange}
                            size="small"
                            id="phone-input"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={6}>
                          <label>Gender</label>
                          <FormControl fullWidth>
                            <Select
                              value={formData.gender}
                              onChange={handleChange}
                              placeholder="Gender"
                              size="small"
                              style={{ marginTop: "5px" }}
                              name="gender"
                              displayEmpty
                              required
                            >
                              <MenuItem value="" disabled>
                                Select Gender
                              </MenuItem>
                              <MenuItem value={1}>Male</MenuItem>
                              <MenuItem value={2}>Female</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={6}>
                          <label>Email</label>
                          <TextField
                            type="email"
                            style={{ width: "100%", marginTop: "5px" }}
                            placeholder="Enter Email"
                            value={formData.email}
                            name="email"
                            onChange={handleChange}
                            size="small"
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                          <label>DOB</label>
                          {/* <TextField
                            type="date"
                            style={{ width: "100%", marginTop: "5px" }}
                            placeholder="Enter DOB"
                            value={formData.dateOfBirth}
                            name="dateOfBirth"
                            onChange={handleChange}
                            size="small"
                            required
                          /> */}
                          <TextField
                            type="date"
                            style={{ width: "100%", marginTop: "5px" }}
                            placeholder="Enter DOB"
                            value={rawDateOfBirth} // This ensures the input works with the date picker
                            name="dateOfBirth"
                            onChange={handleChange}
                            size="small"
                            required
                            InputLabelProps={{
                              shrink: true, // Ensures label stays when date is selected
                            }}
                            inputProps={{
                              max: new Date().toISOString().split("T")[0] // Sets the maximum date to today
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <label>Pan Number</label>
                          <TextField
                            type="text"
                            style={{ width: "100%", marginTop: "5px" }}
                            placeholder="Pan Number"
                            value={formData.pan}
                            name="pan"
                            onChange={handleChange}
                            size="small"
                            required
                            inputProps={{ maxLength: 10, pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}" }} // PAN pattern
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                          <label>Address</label>
                          <TextField
                            type="text"
                            style={{ width: "100%", marginTop: "5px" }}
                            placeholder="Address"
                            value={formData.flatno}
                            name="flatno"
                            onChange={handleChange}
                            size="small"
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                          <label>City</label>
                          <TextField
                            type="text"
                            style={{ width: "100%", marginTop: "5px" }}
                            placeholder="Enter City"
                            value={formData.city}
                            name="city"
                            onChange={handleChange}
                            size="small"
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                          <Box style={{ marginTop: "5px" }}>
                            <label>State</label>
                            <FormControl fullWidth>
                              <Select
                                value={formData.state}
                                onChange={handleChange}
                                name="state"
                                size="small"
                                required
                                displayEmpty

                              >
                                <MenuItem value="" disabled>
                                  Select State
                                </MenuItem>
                                {Object.entries(getState).map(
                                  ([code, name]) => (
                                    <MenuItem key={code} value={code}>
                                      {name}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                          <label>Pincode</label>
                          <TextField
                            type="number"
                            style={{ width: "100%", marginTop: "5px" }}
                            placeholder="Enter Pincode"
                            value={formData.pincode}
                            name="pincode"
                            onChange={handleChange}
                            size="small"
                            required
                            inputProps={{ maxLength: 6 }}
                            onInput={(e) => {
                              if (e.target.value.length > 6) {
                                e.target.value = e.target.value.slice(0, 6);
                              }
                            }}
                          />
                        </Grid>
                        {/* textfiled end */}

                        <Grid item xs={12} sm={12} lg={12}>
                          <Box style={{ marginTop: "20px" }}>
                            <FormControlLabel
                              control={
                                <Checkbox style={{ color: "#044a67" }} />
                              }
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                              label={
                                <Box style={{ display: "flex" }}>
                                  <Typography
                                    style={{
                                      fontSize: "12px",
                                      fontFamily:
                                        "Roboto, Helvetica Neue, sans-serif;",
                                    }}
                                  >
                                    I hereby appoint <b>Due Factory</b> as my
                                    authorised representative to receive my
                                    credit information from{" "}
                                    <span style={{ color: "#044a67" }} onClick={handleOpenModal}>
                                      Experian (Terms and Conditions)
                                    </span>{" "}
                                    for credit enhancement services.
                                  </Typography>
                                </Box>
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <Box style={{ marginTop: "20px" }}>
                            <Button
                              variant="contained"
                              type="submit"
                              style={{
                                background:
                                  formData.firstName &&
                                    formData.surName &&
                                    formData.email &&
                                    formData.gender &&
                                    formData.mobileNo &&
                                    formData.flatno &&
                                    formData.city &&
                                    formData.pincode &&
                                    formData.state &&
                                   rawDateOfBirth &&
                                    isChecked
                                    ? "#044A67"
                                    : "#d3d3d3",
                                color:
                                  formData.firstName &&
                                    formData.surName &&
                                    formData.email &&
                                    formData.gender &&
                                    formData.mobileNo &&
                                    formData.flatno &&
                                    formData.city &&
                                    formData.pincode &&
                                    formData.state &&
                                    rawDateOfBirth &&
                                    isChecked
                                    ? "#FFFFFF"
                                    : "rgba(0, 0, 0, .26)",
                                width: "100%",
                                textTransform: "capitalize",
                              }}
                              disabled={
                                !formData.firstName ||
                                !formData.surName ||
                                !formData.email ||
                                !formData.gender ||
                                !formData.mobileNo ||
                                !formData.flatno ||
                                !formData.city ||
                                !formData.pincode ||
                                !formData.state ||
                                !rawDateOfBirth ||
                                !isChecked
                              }
                            >
                              Pull free Credit Report
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <Box style={{ marginTop: "10px" }}>
                            <Typography
                              style={{
                                fontSize: "12px",
                                fontFamily: "Gilroy,sans-serif",
                              }}
                            >
                              Your personal information is 100% secured with us.
                              We do not share your data with any third party.
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <Box style={{ marginTop: "10px" }}>
                            <FormControlLabel
                              control={<Switch />}
                              label="Get updates on WhatsApp"
                              checked={checkedWhatsApp}
                              onChange={handleChangeCheckedWhats} // <-- Handler function to update state
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* Modal Start */}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Container
             className="modal-container"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: { xs: "90vh", md: "80vh", lg: "80vh" },
                // maxHeight: "80vh",
                backgroundColor: "white",
                padding: { xs: "10px", md: "20px" },
                borderRadius: "20px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                width: {
                  lg: "80%",
                  sm: "80%",
                  xs: "90%",
                },
                overflowY: "hidden", // Hide overflow on the container
              }}
            >
              <Box
                sx={{
                  padding: "20px",
                  flexGrow: 1,
                  overflowY: "auto", // Allow scrolling within this box
                  paddingRight: "10px", // Add some padding to prevent the scrollbar from overlapping content
                }}
              >
                <Typography
                  id="modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    fontSize: "13px",
                    fontFamily: "Arial",
                    color: "black",
                  }}
                >
                  <strong
                    style={{
                      textDecoration: "underline",
                      fontFamily: "CustomFontMedium",
                    }}
                  >
                    {" "}
                    CONSENT IN RELATION TO ACCESS TO CREDIT INFORMATION THROUGH
                    EXPERIAN
                  </strong>
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  This End User Agreement (the <strong>“Agreement”</strong>) is
                  made between you (the <strong>“User” or “You”</strong> ) and{" "}
                  <strong>Due Factory Services Private Limited</strong>{" "}
                  (hereinafter referred to as <strong>“Client”</strong>), a
                  private limited company having its registered office at 745,
                  Phase 5, Udyog Vihar, Gurugram, Haryana 122016 (
                  <strong>“CLIENT”</strong>, <strong>“Us”</strong> or{" "}
                  <strong>“We”</strong> , which term shall include its successors
                  and permitted assigns). The User and CLIENT shall be
                  collectively referred to as the <strong>“Parties”</strong>
                  and individually as a <strong>“Party”</strong> .
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  You hereby consent to Due Factory Services Private Limited being
                  appointed as your authorised representative to receive your
                  Credit Information from Experian for the purpose of Credit
                  Assessment of the End User to Advise him on the credit score
                  enhancement and best loan offers (End Use Purpose) or expiry of
                  6 months from the date the consent is collected; whichever is
                  earlier.
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  <strong>
                    BY EXECUTING THIS AGREEMENT / CONSENT FORM, YOU ARE EXPRESSLY
                    AGREEING TO ACCESS THE EXPERIAN CREDIT INFORMATION REPORT AND
                    CREDIT SCORE, AGGREGATE SCORES, INFERENCES, REFERENCES AND
                    DETAILS (AS DEFINED BELOW)(TOGETHER REFERRED AS “CREDIT
                    INFORMATION”). YOU HEREBY ALSO CONSENT TO SUCH CREDIT
                    INFORMATION BEING PROVIDED BY EXPERIAN TO YOU AND DUE FACTORY
                    SERVICES PRIVATE LIMITED BY USING EXPERIAN TOOLS, ALGORITHMS
                    AND DEVICES AND YOU HEREBY AGREE, ACKNOWLEDGE AND ACCEPT THE
                    TERMS AND CONDITIONS SET FORTH HEREIN.
                  </strong>
                </Typography>{" "}
                <Typography
                  id="modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  <strong style={{ textDecoration: "underline" }}>
                    {" "}
                    Terms and Conditions:
                  </strong>
                </Typography>
                <Typography
                  id="modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  <strong>
                    {" "}
                    Information Collection, Use, Confidentiality, No-Disclosure
                    and Data Purging
                  </strong>
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  DUE FACTORY SERVICES PRIVATE LIMITED shall access your Credit
                  Information as your authorized representative and DUE FACTORY
                  SERVICES PRIVATE LIMITED shall use the Credit Information for
                  limited End Use Purpose consisting of and in relation to the
                  services proposed to be availed by you from DUE FACTORY SERVICES
                  PRIVATE LIMITED. We shall not aggregate, retain, store, copy,
                  reproduce, republish, upload, post, transmit, sell or rent the
                  Credit Information to any other person and the same cannot be
                  copied or reproduced other than as agreed herein and in
                  furtherance to applicable law including the CICRA.
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  The Parties agree to protect and keep confidential the Credit
                  Information both online and offline.
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  The Credit Information shared by you, or received on by us on
                  your behalf shall be destroyed, purged, erased immediately upon
                  the completion of the transaction/ End Use Purpose for which the
                  Credit Information report was procured, this period not being
                  longer than 6 months.
                </Typography>
                <Typography
                  id="modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  <strong> Governing Law and Jurisdiction</strong>
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  The relationship between you and DUE FACTORY SERVICES PRIVATE
                  LIMITED shall be governed by laws of India and all claims or
                  disputes arising there from shall be subject to the exclusive
                  jurisdiction of the courts of Mumbai.
                </Typography>
                <Typography
                  id="modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  <strong>Definitions:</strong>
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  Capitalised terms used herein but not defined above shall have
                  the following meanings:
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  <strong>“Business Day”</strong> means a day (other than a public
                  holiday) on which banks are open for general business in Mumbai.
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  <strong>“Credit Information Report”</strong> means the credit
                  information / scores/ aggregates / variables / inferences or
                  reports which shall be generated by Experian;
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  <strong>“Credit Score”</strong> means the score which shall be
                  mentioned on the Credit Information Report which shall be
                  computed by Experian.
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  <strong>“CICRA”</strong>shall mean the Credit Information
                  Companies (Regulation) Act, 2005 read with the Credit
                  Information Companies Rules, 2006 and the Credit Information
                  Companies Regulations, 2006, and shall include any other rules
                  and regulations prescribed thereunder.
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  PLEASE READ THE ABOVEMENTIONED TERMS AND CONDITIONS AND CLICK ON
                  “ACCEPT” FOLLOWED BY THE LINK BELOW TO COMPLETE THE
                  AUTHORISATION PROCESS/ FOR SHARING OF YOUR CREDIT INFORMATION BY
                  EXPERIAN WITH DUE FACTORY SERVICES PRIVATE LIMITED IN ITS
                  CAPACITY AS YOUR AUTHORISED REPRESENTATIVE.
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  BY CLICKING “ACCEPT” YOU AGREE AND ACCEPT THE DISCLAIMERS AND
                  TERMS AND CONDITIONS SET OUT HEREIN.
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: "CustomFontMedium",
                    marginTop: "10px",
                  }}
                >
                  ** This document is an electronic record in terms of the
                  Information Technology Act, 2000 and Rules made there under, and
                  the amended provisions pertaining to electronic records.
                </Typography>
              </Box>

              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCloseModal}
                  style={{
                    background: "#05517a",
                    color: "white",
                    borderRadius: "10px",
                    marginTop: "20px",
                    fontFamily: "CustomFontMedium",
                  }}
                >
                  Close
                </Button>
              </Box>
            </Container>
          </Modal>
          {/* Modal End */}
        </>
      )}
    </>
  );
}
export default ExperianFullForm;
