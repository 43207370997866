import { Box, Button, Typography, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import HappyTDFGirl from "../assets/imgs/girl5.webp";

import TDFMobile from "../assets/homePage2/TDFPhone2.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function WinnerSection() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    mobile: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    // navigate("/login",{state:{mobile:formData.mobile}})
    if (formData.mobile.length === 10) {
      navigate("/login", { state: { mobile: formData.mobile } });
    } else {
      toast.error("Please enter a valid 10-digit phone number.");
    }
  };
  const [score, setScore] = useState(657); // Initialize score to 657

  useEffect(() => {
    if (score < 702) {
      const timer = setInterval(() => {
        setScore((prevScore) => prevScore + 1);
      }, 100); // Adjust the interval duration (100ms) as needed
      return () => clearInterval(timer);
    }
  }, [score]);
  return (
    <>
      <Box
        id="second-hero-section-start"
        sx={{
          // background: "#fafafa",
          width: "100%",

          height: {
            lg: "100vh",
            md: "100vh",
            sm: "none",
            xs: "none",
          },
          display: {
            lg: "flex", // Visible on large screens
            sm: "flex", // Hidden on small screens
            xs: "none", // Hidden on extra small screens
          },
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            lg={5}
            md={6}
            sx={{
              display: { xs: "none", sm: "none", lg: "flex", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              // sx={{
              //   padding: { xs: "none", sm: "0", lg: "0" },
              //   paddingLeft: { xs: "none", sm: "0", md: "0",lg:"0" },
              //   marginTop: { xs: "none", sm: "0", lg: "0" },
              // }}
              sx={{ padding: "0 40px 0 90px" }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "35px", sm: "30px",md:"38px", lg: "45px" },
                  color: "black",
                  textAlign: { xs: "center", sm: "start", lg: "start" },
                  fontFamily: "CustomFontMedium",
                  fontWeight: 400,

                  lineHeight: { xs: "normal", sm: "normal", lg: "50px" },
                }}
              >
                Better credit health ensures you get higher credit limits on
                cards, easier access to loans at lower rates.
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "18px", sm: "18px",md:"20px", lg: "26px" },
                  color: "black",
                  textAlign: { xs: "center", sm: "start", lg: "start" },
                  padding: { xs: "10px", sm: "0", lg: "0" },
                  marginTop: { xs: "16px", sm: "20px", lg: "20px" },
                  fontFamily: "CustomFontMedium",
                  fontWeight: "400",
                }}
              >
                Your credit score is a snapshot of your financial history. Check
                it now.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "20px",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "normal",
                    md: "normal",
                  },
                }}
              >
                <TextField
                  id="phone-input"
                  variant="outlined"
                  size="large"
                  placeholder="Enter Phone Number"
                  name="mobile"
                  type="number"
                  value={formData.mobile}
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      color: "black", // Text color inside the input
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                    },
                    style: {
                      borderRadius: "8px", // Add border-radius here as well
                    },
                  }}
                  inputProps={{
                    style: {
                      color: "black", // Placeholder color
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  sx={{
                    width: { xs: "40%", sm: "40%", md: "53%" },                 
                  }}
                />
                <Button
                  sx={{
                    "&:hover": {
                      background: "black", // Change this to the color you want on hover
                      color: "#fff", // Optional: Change text color on hover
                    },
                    textTransform: "capitalize",
                    fontFamily: "CustomFontMedium",
                    // fontSize:"20px",
                    fontWeight: "600",
                    background: "#085E7F",
                    color: "white",
                    color: "#fff",
                    width: { xs: "54%", sm: "40%", md: "44%", lg:"40%" },
                    marginLeft: {
                      xs: "10px",
                      sm: "10px",
                      md: "none",
                      lg: "none",
                    },
                    fontSize: {                   
                      sm: "12px",
                      md: "12px",
                      lg: "none",
                    },
                    whiteSpace:"pre",
                    borderRadius: "10px",
                  }}
                  onClick={handleSubmit}
                >
                  Get Your Credit Report
                </Button>
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "20px", sm: "26px", lg: "26px" },
                  color: "#fff",
                  textAlign: { xs: "center", sm: "start", lg: "start" },
                  padding: { xs: "10px", sm: "none", lg: "none" },
                  marginTop: { xs: "16px", sm: "20px", lg: "20px" },
                  fontFamily: "Poppins",
                  fontWeight: "400",
                }}
              >
                We take care of your credit health every step of the way.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            lg={3}
            sx={{
              display: { xs: "none", sm: "none", lg: "flex" },
              alignItems: { xs: "none", sm: "none", lg: "center" },

              // alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Box>
              <img src={TDFMobile} alt="tdfPhone" />
              <Box
                sx={{
                  display: "inline-block",
                  textAlign: "center",
                  position: "absolute",
                  marginTop: "200px",
                  marginLeft: "-190px",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    fontFamily: "CustomFontMedium",
                  }}
                >
                  Your Score
                </span>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "54px",
                    fontFamily: "CustomFontMedium",
                    fontWeight: "600",
                  }}
                >
                  702
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={0}
            sm={4}
            lg={4}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                lg: "flex",
                md: "flex",
                md: "flex",
              },
              alignItems: "flex-end",
              height: "100vh",
            }}
          >
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={HappyTDFGirl}
                alt="TDFGirl"
                width="90%"
                height="100%"
                style={{ display: "flex" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default WinnerSection;
