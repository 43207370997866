import React, { useEffect, useRef, useState } from 'react';

const ScoreChart = ({ score, scoreChange }) => {
    const canvasRef = useRef(null);
    const statusTextRef = useRef(null);
    const scoreValueRef = useRef(null);
    const [animationComplete, setAnimationComplete] = useState(false);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Dynamically calculate canvas size based on window width
        const baseSize = window.innerWidth < 480 ? 160 : 190; // Smaller for mobile
        const dpr = window.devicePixelRatio || 1;
        const canvasWidth = baseSize * dpr;
        const canvasHeight = baseSize * dpr;
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        canvas.style.width = `${baseSize}px`;
        canvas.style.height = `${baseSize}px`;

        const radius = canvasWidth / 2;
        ctx.scale(dpr, dpr);

        const minValue = 300;
        const maxValue = 900;
        const gaugeColors = [
            { threshold: 300, color: '#F22424' },  // Red
            { threshold: 420, color: '#FE670A' },  // Orange-Red
            { threshold: 520, color: '#FE670A' },  // Orange
            { threshold: 540, color: '#FED000' },  // Yellow
            { threshold: 660, color: '#34C637' },  // Light Green
            { threshold: 760, color: '#008042' },  // Dark Green
            { threshold: maxValue, color: '#008042' }  // Dark Green
        ];

        let currentScore = 300;
        const animationSpeed = 15;

        const mapValue = (value, min, max, startAngle, endAngle) => {
            return ((value - min) * (endAngle - startAngle)) / (max - min) + startAngle;
        };

        const drawGauge = () => {
            const startAngle = 0.75 * Math.PI;
            const endAngle = 2.25 * Math.PI;

            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            ctx.globalCompositeOperation = 'source-over'; 

            gaugeColors.forEach((section, index) => {
                const nextThreshold = gaugeColors[index + 1] ? gaugeColors[index + 1].threshold : maxValue;
                const sectionStartAngle = mapValue(section.threshold, minValue, maxValue, startAngle, endAngle);
                const sectionEndAngle = mapValue(nextThreshold, minValue, maxValue, startAngle, endAngle);

                ctx.beginPath();
                ctx.arc(radius / dpr, radius / dpr, (radius - 20) / dpr, sectionStartAngle, sectionEndAngle);

                // Responsive lineWidth
                ctx.lineWidth = 5;
                // window.innerWidth < 480 ? 10 / dpr : 5 / dpr; 
                // Thicker on mobile, smaller on desktop
                ctx.strokeStyle = section.color;
                ctx.stroke();
            });

            drawTick(currentScore);
            if (animationComplete) {
                updateStatus(currentScore);
            }
        };

        const drawTick = (value) => {
            const startAngle = 1.25 * Math.PI;
            const endAngle = 2.70 * Math.PI;
            const angle = mapValue(value, minValue, maxValue, startAngle, endAngle);

            ctx.save();
            ctx.translate(radius / dpr, radius / dpr);
            ctx.rotate(angle);
            const tickLength = (radius - 15) / dpr;  
            const tickWidth = 8 / dpr;  
            const tickHeight = 20 / dpr;  
            const tickRadius = 3 / dpr;  

            ctx.beginPath();
            ctx.moveTo(-tickWidth / 2 + tickRadius, -tickLength);  
            ctx.lineTo(tickWidth / 2 - tickRadius, -tickLength);   
            ctx.quadraticCurveTo(tickWidth / 2, -tickLength, tickWidth / 2, -tickLength + tickRadius); 
            ctx.lineTo(tickWidth / 2, -tickLength + tickHeight - tickRadius); 
            ctx.quadraticCurveTo(tickWidth / 2, -tickLength + tickHeight, tickWidth / 2 - tickRadius, -tickLength + tickHeight); 
            ctx.lineTo(-tickWidth / 2 + tickRadius, -tickLength + tickHeight); 
            ctx.quadraticCurveTo(-tickWidth / 2, -tickLength + tickHeight, -tickWidth / 2, -tickLength + tickHeight - tickRadius); 
            ctx.lineTo(-tickWidth / 2, -tickLength + tickRadius); 
            ctx.quadraticCurveTo(-tickWidth / 2, -tickLength, -tickWidth / 2 + tickRadius, -tickLength); 

            ctx.fillStyle = 'black';
            ctx.fill();
            ctx.restore();
        };

        const updateStatus = (value) => {
            const color = gaugeColors.find((section, index) => {
                const nextThreshold = gaugeColors[index + 1] ? gaugeColors[index + 1].threshold : maxValue;
                return value >= section.threshold && value < nextThreshold;
            })?.color || 'black'; 
        
            if (statusTextRef.current) {
                if (value < 500) {
                    statusTextRef.current.innerText = "Very Poor";
                } else if (value >= 500 && value < 600) {
                    statusTextRef.current.innerText = "Poor";
                } else if (value >= 600 && value < 700) {
                    statusTextRef.current.innerText = "Average";
                } else if (value >= 700 && value < 800) {
                    statusTextRef.current.innerText = "Good";
                } else {
                    statusTextRef.current.innerText = "Excellent";
                }
                statusTextRef.current.style.color = color;
            }
        };

        const animateNeedle = () => {
            if (currentScore < score) {
                currentScore += animationSpeed;
                if (currentScore > score) currentScore = score;

                drawGauge();
                requestAnimationFrame(animateNeedle);
            } else {
                setAnimationComplete(true);
                updateStatus(currentScore);
            }
        };

        animateNeedle();
    }, [score, scoreChange]);

    return (
        <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', position: 'relative' }}>
            <canvas ref={canvasRef}></canvas>
            <div style={{ 
                position: 'absolute', 
                top: '50%', 
                left: '50%', 
                transform: 'translate(-50%, -50%)', 
                textAlign: 'center', 
                fontSize: '22px', 
            }}>
                <span ref={scoreValueRef} style={{ fontFamily: "CustomFontMedium", fontWeight:"700", fontSize: '20px'}}>{score}</span><br />
                <span style={{ fontFamily: "CustomFontMedium", fontWeight:"600", fontSize: '16px'}}>Out of 900</span><br />
                <span ref={statusTextRef} style={{ fontSize: '16px', fontFamily: "CustomFontMedium", fontWeight:"600"}}></span>
            </div>
        </div>
    );
};

export default ScoreChart;
