import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axiosInstance from "../service/axiosInstance";

export default function SettlementListCompo({ listingData }) {

  const handleDownload = (id) => {
    const token = localStorage.getItem("TDFToken");
    if(token){
      const axiosConfig ={
        responseType : "arraybuffer",
        headers:{
          Accept:"application/json",
          Authorization: `Bearer ${token}`,
        }
      }
      axiosInstance.get(`/tdf/api/downloadSettlementLetter/${id}`,axiosConfig)
      .then((response)=>{
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download","settlement.pdf");
        document.body.appendChild(link)
        link.click();
      })
      .catch((err)=>{
        console.log("err:",err)
      })
    } 
  };

  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Bank Name</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Created Date</TableCell>
            <TableCell align="right">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listingData &&
            listingData.map((data, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "#f5f5f5" }, // Change the color as needed
                }}
              >
                <TableCell component="th" scope="row">
                  {data?.bankName}
                </TableCell>
                <TableCell align="right">{data?.amount}</TableCell>
                <TableCell align="right">{data?.status}</TableCell>
                <TableCell align="right">{data?.createdDate}</TableCell>
                <TableCell align="right">
                  {" "}
                  <FileDownloadIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDownload(data?.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
