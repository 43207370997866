import React, { useState } from "react";
import HeaderCoponent from "../Components/HeaderCoponent";
import { Box, Button, Grid, Typography, TextField } from "@mui/material";
import axiosInstance from "../service/axiosInstance";
import { toast } from "react-toastify";
import FotterComponent from "../Components/FotterComponent";

function ContactusPage() {
  const [formData, setFormData] = useState({
    fullname: "",
    phoneNumber: "",
    email: "",
    msg: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [errors, setErrors] = useState({
    fullname: "",
    phoneNumber: "",
    email: "",
    msg: "",
  });
  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!formData.fullname) {
      errors.fullname = "Full Name is Required";
      isValid = false;
    }
    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone Number is Required";
      isValid = false;
    }
    if (!formData.email) {
      errors.email = "Email is Required";
      isValid = false;
    }
    if (!formData.msg) {
      errors.msg = "Message is Required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const token = localStorage.getItem("TDFToken");
      axiosInstance
        .post(
          "/tdf/api/contactUsMsg",
          { formData }, // Empty request body if not needed
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.msg);
        })
        .catch((err) => {
          toast.success(err?.msg);
        });
    }
  };
  return (
    <> 
       <HeaderCoponent />
    <Box 
    style={{marginTop:"20px"}}
    > 


      <Box >
        <Box
          style={{
            width: "100%",
            height: "198px",
            background: "rgba(32, 175, 229, .16)",       
            display: "flex",
            justifyContent: "center",
            alignItems:"center",
          }}
        >
          <Typography style={{ fontSize: "24px", fontWeight: "400", fontFamily:"CustomFontMedium" }}>
            Contact Us
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          // marginTop: {
          //   lg: "80px",
          //   sm: "80px",
          //   xs: "50px",
          // },
          padding: {
            lg: "5% 100px",
            sm: "10% 10%",
            md:"10% 10%",
            xs: "15% 10px",
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={8}
           sx={{padding:{xs:"0 20px",md:"0",lg:"0"}}}
           >
            <Grid item xs={12} sm={6} lg={6}>
            <Box  sx={{width:{xs:"100%",lg:"95%"}}}>

              <div style={{fontFamily:"CustomFontMedium", fontSize:"16px", fontWeight:"400", marginBottom:"1.5rem"}}>Full Name</div>
              <TextField
                type="text"
                variant="standard"
                style={{ width: "100%" }}
                name="fullname"
                value={formData.fullname}
                onChange={handleChange}
                error={!!errors.fullname}
                helperText={errors.fullname}
                placeholder="Enter your full name"
                InputProps={{
                  style: {
                    color: "#212529", // Change the text color of the input field
                  },
                }}
              
              />
                        </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <Box  sx={{width:{xs:"100%",lg:"95%"}}}>
              <div style={{fontFamily:"CustomFontMedium", fontSize:"16px", fontWeight:"400", marginBottom:"1.5rem"}}>Phone Number</div>

                <TextField
                  variant="standard"
                  type="number"
                  style={{ width: "100%" }}
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber}
                  placeholder="Enter your phone number"
                  InputProps={{
                    style: {
                      color: "#212529", // Change the text color of the input field
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
            <Box  sx={{width:"95%"}}>           
              <div style={{fontFamily:"CustomFontMedium", fontSize:"16px", fontWeight:"400", marginBottom:"1.5rem"}}>Email</div>
              <TextField
                variant="standard"
                type="email"
                style={{ width: "100%" }}
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                placeholder="Enter your email"
                InputProps={{
                  style: {
                    color: "#212529", // Change the text color of the input field
                  },
                }}
              />
               </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <div style={{fontFamily:"CustomFontMedium", fontSize:"16px", fontWeight:"400", marginBottom:"1.5rem"}}>Message</div>

              <TextField
                variant="standard"
                type="text"
                style={{ width: "100%" }}
                name="msg"
                value={formData.msg}
                onChange={handleChange}
                error={!!errors.msg}
                helperText={errors.msg}
                placeholder="Enter your message"
                InputProps={{
                  style: {
                    color: "#212529", // Change the text color of the input field
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Button
                type="submit"
                style={{
                  background: "#044a67",
                  color: "#fff",
                  padding: "5px",
                  width: "150px",
                  textTransform: "capitalize",
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          
        </form>
      </Box>
      <FotterComponent />
      </Box>
    </>
  );
}

export default ContactusPage;
