import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Using react-icons for navigation arrows
import myth1 from "../assets/imgs/myth-1.png";
import myth2 from "../assets/imgs/myth-2.png";
import myth3 from "../assets/imgs/myth-3.png";
import loans from "../assets/imgs/loanss.jpg";
import income from "../assets/imgs/income.jpg"


import "./carouselStyles.css";

// Example card data
const cardData = [
  {
    image: myth1, // Replace with actual image paths
    title: "I cannot improve my credit score",
    text: " Of course you CAN! With DF explore options to manage your credit health better and steadily bring up your credit score.",
  },
  {
    image: myth2,
    title: "Settling my loan will improve my credit score.",
    text: "When you settle a loan you pay less than the full amount to close an outstanding debt. So it does not improve your credit score. With DF, get tailored options to close your open credit lines and improve your credit score.",
  },
  {
    image: myth3,
    title: "I should get a loan easily as I have never taken one before.",
    text: "If you have never taken a loan, but you have used a credit card and defaulted on payments, then your credit score has been impacted. Understand how to maintain a strong credit health with DF.",
  },
  {
    image: income,
    title: "The higher my income, the higher will be my credit score.",
    text: "Contrary to popular belief, your income is not related to your credit score, but your loan repaying frequency is. If you have not made regular payments on loans or credit cards then your credit score will decrease. ",
  },
  {
    image: loans,
    title: "Multiple loan applications won't affect my credit score.",
    text: "Multiple loan applications can actually bring down your credit score because every time a lender raises a hard inquiry against your application, it lowers your credit score. Partner with DF to manage your credit health better.",
  },
];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "black",
        right: "10px",
        backgroundColor: "#096386",
        color: "#fff",
        padding:'5px',
        borderRadius:"20px",
        display:"center",
        alignItems:"center",
        justifyContent:"center"
      }}
      onClick={onClick}
    >
      <FaArrowRight color="#fff"/>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "black",
        left: "5px",
        zIndex: 1,
        backgroundColor: "#096386",
        color: "#fff",
        padding:'5px',
        borderRadius:"20px",
          display:"center",
        alignItems:"center",
        justifyContent:"center"
      }}
      onClick={onClick}
    >
      <FaArrowLeft color="#fff" />
    </div>
  );
}

export default function SimpleSlider() {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 cards at once
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      }, 
    ],
  };

  return (
    <Slider {...settings}
    //  style={{padding:"0px 60px"}}
     sx={{padding:{xs:"0", lg:"0px 60px"}}}
      >
      {cardData.map((card, index) => (
        <div key={index}>
          <div style={styles.card}>
            <img src={card.image} alt={card.title} style={styles.image} />
            <div style={styles.content}>
              <h3 style={styles.title}>{card.title}</h3>
              <p style={styles.text}>{card.text}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}

const styles = {
  card: {
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    backgroundColor: "#fff",
    // maxWidth: "500px",
    maxWidth: "450px",
    margin: "10px",
    textAlign: "center",
    height: "630px",
    padding: "20px",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  content: {
    padding: "20px",
  },
  title: {
    fontSize: "25px",
    marginBottom: "10px",
    fontFamily: "CustomFontMedium",
  },
  text: {
    color: "#555",
    fontSize: "20px",
    fontFamily:"CustomFontMedium"
  },
};
