import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import handShake from "../assets/imgs/handshake.png";
import TDF1 from "../assets/imgs/TDF1.png";
import TDF2 from "../assets/imgs/TDF2.png";
import TDF3 from "../assets/imgs/TDF3.png";
import TDF4 from "../assets/imgs/TDF4.png";
import creditScore from "../assets/imgs/settings.jpg";

import solution from "../assets/imgs/solution.png";
import paper from "../assets/imgs/paper.png";
import parsnalized from "../assets/imgs/cardimg.png";
import { useNavigate } from "react-router-dom";
import Cards from "../Components/Cards";

function WhyDueFactory() {
  const navigate = useNavigate();

  const gotoLogin = () => {
    navigate("/login");
  };
  return (
    <>
      <Box
        sx={{
          marginTop: {
            xs: "30px",
            sm: "30px",
            lg: "0",
          },
          marginLeft: {
            xs: "10px",
            lg: "0",
          },
          marginRight: {
            xs: "10px",
            lg: "0",
          },
          padding: {
            xs: "20px",
            lg: "42px 83px",
          },

          // background: "#fafafa",
          // height:"100vh"
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} lg={12}>
            <Box style={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: {
                    lg: "40px",
                    sm: "40px",
                    xs: "35px",
                  },
                  fontWeight: {
                    xs: "600",
                    lg: "600",
                    sm: "500",
                  },
                  fontFamily: "CustomFontMedium",
                   color:"#000000",
                }}
               
              >
                Why go with The Due Factory?
              </Typography>
              <Box style={{display:"flex", justifyContent:"center"}}>

                <Box sx={{ display: "flex", justifyContent: "center", width:{xs:"100%", sm:"100%" ,md:"50%"}  }}>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "18px",
                        sm: "18px",
                        xs: "20px",
                      },
                      maxWidth: {
                        xs: "100%",
                        lg: "100%",
                      },
                      fontFamily: "CustomFontMedium",
                    }}
                  >
                    Due Factory is a credit improvement platform that helps you
                    improve your credit worthiness, and guides you in building
                    strong financial health.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Cards />

        {/* Card Section start */}
        {/* <Box style={{ marginTop: "20px" }}>
          <Grid
            container
            spacing={{ xs: 4, sm: 1, lg: 1}}
            sx={{ marginTop: "20px" }}
          >
            <Grid item xs={12} sm={6} lg={3} md={6}>
              <Card
                sx={{
                  height: "180px", // Fixed height
                  borderRadius: "16px",
                  // boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  boxShadow:
                    "0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)",
                  border: "1px solid rgba(0,0,0,.125)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Ensures space between text and image
                  // padding: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      fontFamily: "CustomFontMedium",
                    }}
                  >
                    We Are Here For YOU
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "18px",
                        sm: "18px",
                        xs: "16px",
                      },
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px", // Adds space between the two text lines
                    }}
                  >
                    Unlike a collection agency that represents the bank, we
                    represent YOU.
                  </Typography>
                </Box>
                <Box
                //  sx={{ padding: "10px" }}
                >
                  <img src={TDF1} alt="handShake" width="70px" />
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} lg={3} md={6}>
              <Card
                sx={{
                  height: "180px", // Fixed height
                  borderRadius: "16px",
                  // boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  boxShadow:
                    "0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)",
                  border: "1px solid rgba(0,0,0,.125)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Ensures space between text and image
                  // padding: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      fontFamily: "CustomFontMedium",
                    }}
                  >
                    Single Solution For All Credit Issues
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "18px",
                        sm: "18px",
                        xs: "16px",
                      },
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px", // Adds space between the two text lines
                    }}
                  >
                    We offer a holistic, overall solution for resolving multiple
                    lines of default.
                  </Typography>
                </Box>
                <Box>
                  <img src={TDF2} alt="handShake" width="70px" />
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} md={6}>
              <Card
                sx={{
                  height: "180px", // Fixed height
                  borderRadius: "16px",
                  // boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  boxShadow:
                    "0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)",
                  border: "1px solid rgba(0,0,0,.125)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Ensures space between text and image
                  // padding: "10px",
                  fontFamily: "CustomFontMedium",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      fontFamily: "CustomFontMedium",
                    }}
                  >
                    Leave The Paperwork To Us
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "18px",
                        sm: "18px",
                        xs: "16px",
                      },
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px", // Adds space between the two text lines
                    }}
                  >
                    We manage all correspondence and documentation with your
                    lenders on your behalf.
                  </Typography>
                </Box>
                <Box>
                  <img src={TDF4} alt="handShake" width="67px" />
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} md={6}>
              <Card
                sx={{
                  height: "180px", // Fixed height
                  borderRadius: "16px",
                  // boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  boxShadow:
                    "0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)",
                  border: "1px solid rgba(0,0,0,.125)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Ensures space between text and image
                  // padding: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      fontFamily: "CustomFontMedium",
                    }}
                  >
                    Personalized Solutions
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "18px",
                        sm: "18px",
                        xs: "16px",
                      },
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px", // Adds space between the two text lines
                    }}
                  >
                    Custom-made suggestions to build your credit health
                  </Typography>
                </Box>
                <Box>
                  <img src={TDF1} alt="handShake" width="67px" />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box> */}
        {/* Card Section end */}
        <Box
          style={{ padding: "10px", marginTop: "10px" }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              lg={6}
              md={6}
              sx={{
                display: { xs: "none", sm: "none", lg: "flex", md: "flex" },
                alignItems: "center",
              }}
            >
              <Box >
                <img
                  src={creditScore}
                  alt="creditScore"
                  width="80%"
                  style={{ borderRadius: "20px" }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={6}
              md={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Box
                sx={{ textAlign: { xs: "center", md: "start" }, marginTop:{xs:"40px", lg:"10px"}  }}
              >
                <Typography
                  sx={{
                    lineHeight: { xs: "none", sm: "55px", lg: "55px" },
                    fontSize: { xs: "35px", sm: "50px", lg: "50px" },
                    fontFamily: "CustomFontMedium",
                    fontWeight: "600",
                  }}
                >
                  Want to improve your credit score but don't know how?
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      lg: "18px",
                      sm: "18px",
                      xs: "20px",
                    },
                    fontWeight: { xs: "600", md: "400" },
                    // marginTop: "10px",
                      marginTop: { xs: "20px", md: "10px" },

                    fontFamily: "CustomFontMedium",
                  }}
                >
                  Due Factory Can Help You Improve Your Credit Score
                </Typography>
                <Button
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    fontSize: { xs: "13px", sm: "18px", lg: "18px" },
                    background: "#044A67",
                    color: "#fff",
                    marginTop: "20px",
                    textTransform: "capitalize",
                    height: "50px",
                    fontFamily: "CustomFontMedium",
                    borderRadius: "10px",
                    "&:hover": {
                      background: "#044A67", // Change this to the color you want on hover
                      color: "#fff", // Optional: Change text color on hover
                    },
                    borderRadius: "26px",
                    padding: "0 25px",
                  }}
                  onClick={gotoLogin}
                >
                  Let's increase your credit score!
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default WhyDueFactory;
